import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AccessDenied from "../components/AccessDenied";
import Alert from "../components/Alert";
import AlertSuccess from "../components/Alertsuccess";
import HesapKes from "../components/HesapKes";
import OncedenOde from "../components/OncedenOde";
import { base_url,img_url } from '../api/index';
import { Helmet } from "react-helmet";
import DontActiveAcount from "../components/DontActiveAcount";
const getHeaders = () => ({
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

function MusteriSiparisEkle() {
  const { id } = useParams(); // Get the order ID from the URL
  const [urunType, setUrunType] = useState(0);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [stockGroups, setStockGroups] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [orderStocks, setOrderStocks] = useState([]);
  const fis = localStorage.getItem('fisYazisi')
//   const [totalPrice, setTotalPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState({}); // Default total price as a number
  const navigate = useNavigate();
  const [odersIdMassa, setOrdersIdMassa] = useState({});
  const [oncedenOdePopop, setoncedenodePopop] = useState(false);
  const [refreshFetch, setrefreshfetch] = useState(false);
  const [HesabKes,setHesabKes] =useState(false)
  const [accessDenied, setAccessDenied] = useState(false);
  const [ActiveUser, setActiveUser] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({
    name: "",
    phone: "",
    address: "",
  });
  const fetchStockGroups = async () => {
    try {
      const response = await axios.get(
        `${base_url}/stock-groups`,
        getHeaders()
      );
      setStockGroups(response.data);
    } catch (error) {
      if (error.response && error.response.status === 403 && error.response.data.message === "User does not belong to any  active restaurant.") {
        setActiveUser(true); // Set access denied if response status is 403
    }
      if (error.response && error.response.status === 403 && error.response.data.message === "Forbidden") {
        // setAccessDenied(true); // Set access denied if response status is 403
    } else {
        console.error('Error loading customers:', error);
    }
    }
  };

  const fetchStocks = async (groupId) => {
    try {
      const response = await axios.get(`${base_url}/stocks`, {
        ...getHeaders(),
        params: groupId === 0 ? {} : { stock_group_id: groupId },
      });
      setStocks(response.data);
    } catch (error) {
      if (error.response && error.response.status === 403 && error.response.data.message === "User does not belong to any  active restaurant.") {
        setActiveUser(true); // Set access denied if response status is 403
    }
      if (error.response && error.response.status === 403 && error.response.data.message === "Forbidden") {
        // setAccessDenied(true); // Set access denied if response status is 403
    } else {
        console.error('Error loading customers:', error);
    }
    }
  };

  const handleDeleteOrder = async () => {
    try {
      await axios.delete(
        `${base_url}/quick-orders/${id}`,
        getHeaders()
      );
      navigate("/siparisler");
    } catch (error) {
      if (error.response && error.response.status === 403 && error.response.data.message === "User does not belong to any  active restaurant.") {
        setActiveUser(true); // Set access denied if response status is 403
    }
      if (error.response && error.response.status === 403 && error.response.data.message === "Forbidden") {
        setAccessDenied(true); // Set access denied if response status is 403
    } else {
      
        console.error("Error deleting order:", error);
    }
    }
  };

  const fetchCustomerInfo = async () => {
    try {
      const response = await axios.get(
        `${base_url}/quick-orders/${id}`,
        getHeaders()
      );
      const { name, phone, address, order } = response.data;
      setOrdersIdMassa({
        id: response.data.order.id,
        total_price: response.data.order.total_price,
        total_prepayment: response.data.order.total_prepayment,
      });
      const total = response.data.order.total_price;
      const total_prepare = response.data.order.total_prepayment??0;
      const kalanMebleg = total -total_prepare ;
      setTotalPrice({
          total:total,
          total_prepare:total_prepare,
          kalan:kalanMebleg
      });
      console.log(response.data.order, "sifaris");
      // console.log(re);

      setCustomerInfo({ name, phone, address });

      const filteredStocks = order.stocks.map((stock) => ({
        id: stock.id,
        name: stock.name,
        price: stock.total_price,
        quantity: stock.pivot.quantity,
      }));
      setOrderStocks(filteredStocks);
    //   setTotalPrice(order.total_price);
    } catch (error) {
      if (error.response && error.response.status === 403 && error.response.data.message === "User does not belong to any  active restaurant.") {
        setActiveUser(true); // Set access denied if response status is 403
    }
      if (error.response && error.response.status === 403 && error.response.data.message === "Forbidden") {
        // setAccessDenied(true); // Set access denied if response status is 403
    } else {
        console.error('Error loading customers:', error);
    }
    }
  };

  const replaceImage = (url) => {
    return `${img_url}/${url}`;
  };

  useEffect(() => {
    fetchStockGroups();
    if (id) {
      fetchCustomerInfo();
    }
  }, [id,refreshFetch]);

  useEffect(() => {
    fetchStocks(urunType);
    localStorage.setItem("urunType", urunType);
  }, [urunType]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerInfo({
      ...customerInfo,
      [name]: value,
    });
  };

  const updateCustomerInfo = async () => {
    try {
      await axios.put(
        `${base_url}/quick-orders/${id}`,
        customerInfo,
        getHeaders()
      );
      setAlertSuccess(true);
    } catch (error) {
      if (error.response && error.response.status === 403 && error.response.data.message === "User does not belong to any  active restaurant.") {
        setActiveUser(true); // Set access denied if response status is 403
    }
      if (error.response && error.response.status === 403 && error.response.data.message === "Forbidden") {
        setAccessDenied(true); // Set access denied if response status is 403
    } else {
      console.error("Error updating customer info:", error);
       
    }
    }
  };

  const handleAddSifaris = async (stockId) => {
    try {
      await axios.post(
        `${base_url}/quick-orders/${id}/add-stock`,
        {
          stock_id: stockId,
          quantity: 1, // Default quantity
        },
        getHeaders()
      );
      fetchCustomerInfo();
    } catch (error) {
      if (error.response && error.response.status === 403 && error.response.data.message === "User does not belong to any  active restaurant.") {
        setActiveUser(true); // Set access denied if response status is 403
    }
      if (error.response && error.response.status === 403 && error.response.data.message === "Forbidden") {
        setAccessDenied(true); // Set access denied if response status is 403
    } else {
        
        console.error("Error adding stock to order:", error);
    }
    }
  };

  const handleRemoveSifaris = async (stockId, quantity) => {
    try {
      await axios.post(
        `${base_url}/quick-orders/${id}/subtract-stock`,
        {
          stock_id: stockId,
          quantity: quantity || 1, // Default quantity
        },
        getHeaders()
      );
      fetchCustomerInfo();
    } catch (error) {
      if (error.response && error.response.status === 403 && error.response.data.message === "User does not belong to any  active restaurant.") {
        setActiveUser(true); // Set access denied if response status is 403
    }
      if (error.response && error.response.status === 403 && error.response.data.message === "Forbidden") {
        setAccessDenied(true); // Set access denied if response status is 403
    } else {
       
        console.error("Error removing stock from order:", error);
    }
    }
  };
  const handlePrint = () => {
    const printContent = `
      <html>
      <head>
        <title>Order Print</title>
        <style>
          body { font-family: Arial, sans-serif; }
          .container { max-width: 800px; margin: auto; }
          .header { text-align: center; margin-bottom: 20px; }
          .order-details { margin-bottom: 20px; }
          .fis {text-align: center; margin-top: 20px; }
          table { width: 100%; border-collapse: collapse; }
          th, td { padding: 10px; border: 1px solid #ddd; text-align: left; }
          th { background-color: #f4f4f4; }
        </style>
      </head>
      <body>
        <div class="container">
          <div class="header">
            <h1>Sifariş təfərrüatları</h1>
            <p><strong>Müşteri:</strong> ${customerInfo.name}</p>
            <p><strong>Telefon nömrəsi:</strong> ${customerInfo.phone}</p>
            <p><strong>Address:</strong> ${customerInfo.address}</p>
          </div>
          <div class="order-details">
            <h2>Sifariş Detallari</h2>
            <table>
              <thead>
                <tr>
                  <th>Adi</th>
                  <th>Miktari</th>
                  <th>Məbləğ (1)</th>
                  <th>Ümumi məbləğ</th>
                </tr>
              </thead>
              <tbody>
                ${orderStocks.map(stock => `
                  <tr>
                    <td>${stock.name}</td>
                    <td>${stock.quantity}</td>
                    <td>${stock.price}</td>
                    <td>${stock.price * stock.quantity}</td>
                  </tr>
                `).join('')}
              </tbody>
            </table>
            <div>
              <p><strong>Ümumi məbləğ:</strong> ${totalPrice.total}</p>
              <p><strong>Artıq ödənilib:</strong> ${totalPrice.total_prepare}</p>
              <p><strong>Qaliq:</strong> ${totalPrice.kalan}</p>
            </div>
            <div class="fis">

            <strong>${fis}</strong>
            </div>
          </div>
        </div>
      </body>
      </html>
    `;

    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.open();
    printWindow.document.write(printContent);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };
  if (ActiveUser) return <DontActiveAcount onClose={setActiveUser}/>;
  if (accessDenied) return <AccessDenied onClose={setAccessDenied}/>;
  return (
    <>
                                 <Helmet>
        <title> Müştəri sifarişi əlavə edin | Smartcafe</title>
        <meta name="description" content='Restoran proqramı | Kafe - Restoran idarə etmə sistemi ' />
      </Helmet>
      <section className="p-4 flex flex-col gap-5">
        {/* Customer Information */}
        <div className="border rounded bg-[#fafbfc] p-4 mb-5">
          <h2 className="text-lg font-semibold mb-4">Müşteri Bilgileri</h2>
          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Ad Soyad</label>
            <input
              className="border rounded py-2 px-3 outline-none text-sm"
              type="text"
              name="name"
              value={customerInfo.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Telefon</label>
            <input
              className="border rounded py-2 px-3 outline-none text-sm"
              type="text"
              name="phone"
              value={customerInfo.phone}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Adres</label>
            <input
              className="border rounded py-2 px-3 outline-none text-sm"
              type="text"
              name="address"
              value={customerInfo.address}
              onChange={handleInputChange}
            />
          </div>
          <button
            onClick={updateCustomerInfo}
            className="bg-sky-600 font-medium py-2 px-4 rounded text-white w-full"
          >
            Güncelle
          </button>
        </div>

        {/* Orders and Menu */}
        <div className="flex flex-col lg:flex-row gap-5">
          {/* Orders Section */}
          <div className="border rounded bg-[#fafbfc] p-4 flex-2">
            <h2 className="text-lg font-semibold mb-4">Siparişler</h2>
            <table className="w-full text-left border rounded bg-[#fafbfc] mb-4">
              <thead className="border-b border-gray-400 bg-gray-100">
                <tr className="border-b border-gray-300">
                  <th className="p-3 font-semibold">Sil</th>
                  <th className="p-3 font-semibold">Adı</th>
                  <th className="p-3 font-semibold">Miktar</th>
                  <th className="p-3 font-semibold">Toplam</th>
                </tr>
              </thead>
              <tbody>
                {orderStocks.map((stock) => (
                  <tr key={stock.id} className="font-medium">
                    <td
                      onClick={() =>
                        handleRemoveSifaris(stock.id, stock.quantity)
                      }
                      className="text-red-500 p-3 cursor-pointer"
                    >
                     <i className="fa-solid fa-trash-can"></i>
                    </td>
                    <td className="p-3">{stock.name}</td>
                    <td className="p-3 flex items-center">
                      <button
                        onClick={() => handleRemoveSifaris(stock.id)}
                        className="bg-red-500 font-medium text-center py-2 w-8 rounded-l text-white"
                      >
                        -
                      </button>
                      <input
                        type="number"
                        value={stock.quantity}
                        className="p-2 text-right border-y text-[15px] w-20"
                        readOnly
                      />
                      <button
                        onClick={() => handleAddSifaris(stock.id)}
                        className="bg-green-500 font-medium text-center py-2 w-8 rounded-r text-white"
                      >
                        +
                      </button>
                    </td>
                    <td className="p-3 text-right">{stock.price} ₼</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex flex-col gap-2">
            {
                            totalPrice.total ? (
                                <>
                                       <div className='flex items-center gap-2'>
                            <span className='font-semibold'>Ön ödeme:</span>
                            <input type="text" value={totalPrice.total_prepare} readOnly className='border rounded-l p-2 text-right w-20' />
                            <button onClick={()=>setoncedenodePopop(true)} className='bg-green-500 text-white py-1 px-2 rounded-r focus:outline-none'>+</button>
                        </div>
                                                       <div className='flex items-center gap-2'>
                            <span className='font-semibold'>Toplam:</span>
                            <div className='flex items-center'>
                                <div className='border font-medium py-1 px-2 rounded-l bg-gray-100'>₼</div>
                                <div className='border border-l-0 rounded-r p-2 w-32 text-right bg-gray-100'>{totalPrice.total}</div>
                            </div>
                        </div>
                        <div className='flex items-center gap-2'>
                            <span className='text-green-600 font-semibold'>Artıq ödənilib :</span>
                            <div className='flex items-center'>
                                <div className='border font-medium py-1 px-2 rounded-l bg-gray-100'>₼</div>
                                <div className='border border-l-0 rounded-r p-2 w-32 text-right bg-gray-100'>{totalPrice.total_prepare}</div>
                            </div>
                        </div>
                        <div className='flex items-center gap-2'>
                            <span className='text-red-600 font-semibold'>Qalıq :</span>
                            <div className='flex items-center'>
                                <div className='border font-medium py-1 px-2 rounded-l bg-gray-100'>₼</div>
                                <div className='border border-l-0 rounded-r p-2 w-32 text-right bg-gray-100'>{totalPrice.kalan}</div>
                            </div>
                        </div>
                                </>
                            ) : "Sifaris yoxdur"
                        }
              <div className="flex flex-col sm:flex-row gap-2 mt-3">
                {
                  totalPrice.total ? (
                                  <>
                                    <button onClick={()=>setHesabKes(true)} className="bg-green-500 font-medium py-2 px-4 rounded text-white flex-1 mb-2 sm:mb-0">
                  Hesap kes
                </button>
                <button onClick={handlePrint} className="bg-sky-600 font-medium py-2 px-4 rounded text-white flex-1 mb-2 sm:mb-0">
                Qəbz çap edin
                </button>
                                  </>
                  ):""
                }

                <button
                  onClick={() => handleDeleteOrder()}
                  className="bg-slate-900 font-medium py-2 px-4 rounded text-white flex-1"
                >
                  Ləğv edin
                </button>
              </div>
            </div>
          </div>

          {/* Menu Section */}
          <div className="border rounded bg-gray-50 p-4 flex-1">
            <div className="flex flex-wrap gap-2 mb-4">
              <button
                onClick={() => setUrunType(0)}
                className={`p-2 btn-filter ${
                  urunType === 0
                    ? "bg-blue-500 text-white border-blue-500"
                    : "bg-gray-200"
                }`}
              >
                Hamısı
              </button>
              {stockGroups.map((group) => (
                <button
                  key={group.id}
                  onClick={() => setUrunType(group.id)}
                  className={`p-2 btn-filter ${
                    urunType === group.id
                      ? "bg-blue-500 text-white border-blue-500"
                      : "bg-gray-200"
                  }`}
                >
                  {group.name}
                </button>
              ))}
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 gap-4">
              {stocks.map((stock) => (
                <div
                  onClick={() => handleAddSifaris(stock.id)}
                  key={stock.id}
                  className="bg-white border rounded-lg p-4 shadow-md flex flex-col"
                >
                  <div className="w-full h-32 bg-gray-300 mb-2">
                    <img
                      src={replaceImage(stock.image)}
                      alt={stock.name}
                      className="w-full h-full object-contain"
                    />
                  </div>
                  <div className="flex-grow">
                    <span className="block text-lg font-semibold">{` ${stock.price} ₼`}</span>
                    <p className="text-sm text-gray-600 truncate">
                      {stock.name}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {alertSuccess && <AlertSuccess setAlertSuccess={setAlertSuccess} />}
    {  oncedenOdePopop && (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center p-4 z-50">
        <div className="w-full max-w-3xl bg-white rounded-lg shadow-lg overflow-hidden border border-gray-300 relative">
          <div className="bg-gray-200 p-4 flex justify-between items-center border-b">
            <h3 className="text-xl font-semibold text-gray-800">{customerInfo.name}</h3>
            <button
              onClick={() => setoncedenodePopop(false)}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
              aria-label="Close"
            >
              &times;
            </button>
          </div>
          <div className="p-4 max-h-[80vh] overflow-y-auto">
            <OncedenOde
              odersId={odersIdMassa}
              setrefreshfetch={setrefreshfetch}
            />
          </div>
        </div>
      </div>
      )}
                  {
  HesabKes && (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center p-4 z-50">
      <div className="w-full max-w-3xl bg-white rounded-lg shadow-lg overflow-hidden border border-gray-300 relative">
        <div className="bg-gray-200 p-4 flex justify-between items-center border-b">
          <h3 className="text-xl font-semibold text-gray-800"></h3>
          <button
            onClick={() => setHesabKes(false)}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
            aria-label="Close"
          >
            &times;
          </button>
        </div>
        <div className="p-4 max-h-[80vh] overflow-y-auto">
          <HesapKes orderId={odersIdMassa} totalAmount={totalPrice.kalan} />
        </div>
      </div>
    </div>
  )
}
    </>
  );
}

export default MusteriSiparisEkle;

// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import AccessDenied from "../components/AccessDenied";
// import Alert from "../components/Alert";
// import AlertSuccess from "../components/Alertsuccess";
// import HesapKes from "../components/HesapKes";
// import OncedenOde from "../components/OncedenOde";

// const getHeaders = () => ({
//   headers: {
//     Authorization: `Bearer ${localStorage.getItem("token")}`,
//     "Content-Type": "application/json",
//     Accept: "application/json",
//   },
// });

// function MusteriSiparisEkle() {
//   const { id } = useParams(); // Get the order ID from the URL
//   const [urunType, setUrunType] = useState(0);
//   const [alertSuccess, setAlertSuccess] = useState(false);
//   const [stockGroups, setStockGroups] = useState([]);
//   const [stocks, setStocks] = useState([]);
//   const [orderStocks, setOrderStocks] = useState([]);
//   const [totalPrice, setTotalPrice] = useState({});
//   const navigate = useNavigate();
//   const [odersIdMassa, setOrdersIdMassa] = useState({});
//   const [oncedenOdePopop, setoncedenodePopop] = useState(false);
//   const [refreshFetch, setrefreshfetch] = useState(false);
//   const [HesabKes, setHesabKes] = useState(false);
//   const [accessDenied, setAccessDenied] = useState(false);
//   const [customerInfo, setCustomerInfo] = useState({
//     name: "",
//     phone: "",
//     address: "",
//   });

//   const fetchStockGroups = async () => {
//     try {
//       const response = await axios.get(
//         `${base_url}/stock-groups",
//         getHeaders()
//       );
//       setStockGroups(response.data);
//     } catch (error) {
//       if (error.response && error.response.status === 403 && error.response.data.message === "Forbidden") {
//         setAccessDenied(true);
//       } else {
//         console.error('Error loading stock groups:', error);
//       }
//     }
//   };

//   const fetchStocks = async (groupId) => {
//     try {
//       const response = await axios.get(`${base_url}/stocks", {
//         ...getHeaders(),
//         params: groupId === 0 ? {} : { stock_group_id: groupId },
//       });
//       setStocks(response.data);
//     } catch (error) {
//       if (error.response && error.response.status === 403 && error.response.data.message === "Forbidden") {
//         setAccessDenied(true);
//       } else {
//         console.error('Error loading stocks:', error);
//       }
//     }
//   };

//   const handleDeleteOrder = async () => {
//     try {
//       await axios.delete(
//         `${base_url}/quick-orders/${id}`,
//         getHeaders()
//       );
//       navigate("/siparisler");
//     } catch (error) {
//       if (error.response && error.response.status === 403 && error.response.data.message === "Forbidden") {
//         setAccessDenied(true);
//       } else {
//         console.error("Error deleting order:", error);
//       }
//     }
//   };

//   const fetchCustomerInfo = async () => {
//     try {
//       const response = await axios.get(
//         `${base_url}/quick-orders/${id}`,
//         getHeaders()
//       );
//       const { name, phone, address, order } = response.data;
//       setOrdersIdMassa({
//         id: response.data.order.id,
//         total_price: response.data.order.total_price,
//         total_prepayment: response.data.order.total_prepayment,
//       });
//       const total = response.data.order.total_price;
//       const total_prepare = response.data.order.total_prepayment ?? 0;
//       const kalanMebleg = total - total_prepare;
//       setTotalPrice({
//         total: total,
//         total_prepare: total_prepare,
//         kalan: kalanMebleg
//       });
//       setCustomerInfo({ name, phone, address });

//       const filteredStocks = order.stocks.map((stock) => ({
//         id: stock.id,
//         name: stock.name,
//         price: stock.total_price,
//         quantity: stock.pivot.quantity,
//       }));
//       setOrderStocks(filteredStocks);
//     } catch (error) {
//       if (error.response && error.response.status === 403 && error.response.data.message === "Forbidden") {
//         setAccessDenied(true);
//       } else {
//         console.error('Error loading customer info:', error);
//       }
//     }
//   };

//   const replaceImage = (url) => {
//     return `${img_url}/${url}`;
//   };

//   useEffect(() => {
//     fetchStockGroups();
//     if (id) {
//       fetchCustomerInfo();
//     }
//   }, [id, refreshFetch]);

//   useEffect(() => {
//     fetchStocks(urunType);
//     localStorage.setItem("urunType", urunType);
//   }, [urunType]);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setCustomerInfo({
//       ...customerInfo,
//       [name]: value,
//     });
//   };

//   const updateCustomerInfo = async () => {
//     try {
//       await axios.put(
//         `${base_url}/quick-orders/${id}`,
//         customerInfo,
//         getHeaders()
//       );
//       setAlertSuccess(true);
//     } catch (error) {
//       if (error.response && error.response.status === 403 && error.response.data.message === "Forbidden") {
//         setAccessDenied(true);
//       } else {
//         console.error("Error updating customer info:", error);
//       }
//     }
//   };

//   const handleAddSifaris = async (stockId) => {
//     try {
//       await axios.post(
//         `${base_url}/quick-orders/${id}/add-stock`,
//         {
//           stock_id: stockId,
//           quantity: 1, // Default quantity
//         },
//         getHeaders()
//       );
//       fetchCustomerInfo();
//     } catch (error) {
//       if (error.response && error.response.status === 403 && error.response.data.message === "Forbidden") {
//         setAccessDenied(true);
//       } else {
//         console.error("Error adding stock to order:", error);
//       }
//     }
//   };

//   const handleRemoveSifaris = async (stockId, quantity) => {
//     try {
//       await axios.post(
//         `${base_url}/quick-orders/${id}/subtract-stock`,
//         {
//           stock_id: stockId,
//           quantity: quantity || 1, // Default quantity
//         },
//         getHeaders()
//       );
//       fetchCustomerInfo();
//     } catch (error) {
//       if (error.response && error.response.status === 403 && error.response.data.message === "Forbidden") {
//         setAccessDenied(true);
//       } else {
//         console.error("Error removing stock from order:", error);
//       }
//     }
//   };

//   const handlePrint = () => {
//     const printContent = `
//       <html>
//       <head>
//         <title>Order Print</title>
//         <style>
//           body { font-family: Arial, sans-serif; }
//           .container { max-width: 800px; margin: auto; }
//           .header { text-align: center; margin-bottom: 20px; }
//           .order-details { margin-bottom: 20px; }
//           table { width: 100%; border-collapse: collapse; }
//           th, td { padding: 10px; border: 1px solid #ddd; text-align: left; }
//           th { background-color: #f4f4f4; }
//         </style>
//       </head>
//       <body>
//         <div class="container">
//           <div class="header">
//             <h1>Order Details</h1>
//             <p><strong>Customer:</strong> ${customerInfo.name}</p>
//             <p><strong>Phone:</strong> ${customerInfo.phone}</p>
//             <p><strong>Address:</strong> ${customerInfo.address}</p>
//           </div>
//           <div class="order-details">
//             <h2>Order Items</h2>
//             <table>
//               <thead>
//                 <tr>
//                   <th>Name</th>
//                   <th>Quantity</th>
//                   <th>Price</th>
//                   <th>Total</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 ${orderStocks.map(stock => `
//                   <tr>
//                     <td>${stock.name}</td>
//                     <td>${stock.quantity}</td>
//                     <td>${stock.price}</td>
//                     <td>${stock.price * stock.quantity}</td>
//                   </tr>
//                 `).join('')}
//               </tbody>
//             </table>
//             <div>
//               <p><strong>Total Price:</strong> ${totalPrice.total}</p>
//               <p><strong>Prepayment:</strong> ${totalPrice.total_prepare}</p>
//               <p><strong>Remaining:</strong> ${totalPrice.kalan}</p>
//             </div>
//           </div>
//         </div>
//       </body>
//       </html>
//     `;

//     const printWindow = window.open('', '', 'height=600,width=800');
//     printWindow.document.open();
//     printWindow.document.write(printContent);
//     printWindow.document.close();
//     printWindow.focus();
//     printWindow.print();
//   };

//   if (accessDenied) {
//     return <AccessDenied />;
//   }

//   return (
//     <div className="container">
//       {alertSuccess && <AlertSuccess message="Information updated successfully!" />}
//       {oncedenOdePopop && <OncedenOde onClose={() => setoncedenodePopop(false)} />}
//       {HesabKes && <HesapKes onClose={() => setHesabKes(false)} />}
//       <div className="row">
//         <div className="col-md-6">
//           <h3>Customer Information</h3>
//           <input
//             type="text"
//             name="name"
//             value={customerInfo.name}
//             onChange={handleInputChange}
//             placeholder="Name"
//           />
//           <input
//             type="text"
//             name="phone"
//             value={customerInfo.phone}
//             onChange={handleInputChange}
//             placeholder="Phone"
//           />
//           <input
//             type="text"
//             name="address"
//             value={customerInfo.address}
//             onChange={handleInputChange}
//             placeholder="Address"
//           />
//           <button onClick={updateCustomerInfo}>Update Info</button>
//         </div>
//         <div className="col-md-6">
//           <h3>Order Items</h3>
//           <ul>
//             {orderStocks.map((stock) => (
//               <li key={stock.id}>
//                 {stock.name} - {stock.quantity} x {stock.price}
//                 <button onClick={() => handleRemoveSifaris(stock.id, stock.quantity)}>Remove</button>
//               </li>
//             ))}
//           </ul>
//         </div>
//       </div>
//       <button onClick={handlePrint}>Print Order</button>
//       <button onClick={handleDeleteOrder}>Delete Order</button>
//     </div>
//   );
// }

// export default MusteriSiparisEkle;
