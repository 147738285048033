// // restaran 103 
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';
// import MenuItemPopup from '../components/MenuItemPopup'; // Ensure this path is correct
// import QrcodeDontActive from '../components/QrcodeDontActive';
// import QrcodeDontOrder from '../components/QrcodeDontOrder';
// import { base_url, img_url } from '../api/index';
// import { Helmet } from 'react-helmet';

// const getAuthHeaders = () => {
//   const token = localStorage.getItem("token");
//   return {
//     headers: {
//       'Authorization': `Bearer ${token}`,
//       'Content-Type': 'application/json',
//       'Accept': 'application/json',
//     }
//   };
// };

// const RestaurantMenu = () => {
//   const [restaurant, setRestaurant] = useState({ name: '', logo: '', masaName: '' });
//   const [menu, setMenu] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [selectedItem, setSelectedItem] = useState(null);
//   const [approvedOrders, setApprovedOrders] = useState([]);
//   const [pendingOrders, setPendingOrders] = useState([]);
//   const { token } = useParams(); // Extract token from URL parameters
//   const [Qrcodedont, setQrcodedont] = useState(false);
//   const [QrcodedontOrde, setQrcodedontOrder] = useState(false);

//   useEffect(() => {
//     const fetchMenuData = async () => {
//       try {
//         const response = await axios.get(`${base_url}/qr/${token}/menu`, getAuthHeaders());
//         setMenu(response.data.stockGroups || []); // Ensure it's an array
//         setRestaurant({
//           name: response.data.restaurant.name,
//           logo: `${img_url}/${response.data.restaurant.logo}`,
//           masaName: response.data.table.name
//         });
//       } catch (error) {
//         if (error.response && error.response.status === 400) {
//           setQrcodedont(true); // Set access denied if response status is 400
//           setRestaurant({
//             name: error.response.data.restaurant.name,
//             logo: `${img_url}/${error.response.data.restaurant.logo}`
//           });
//         } else {
//           console.error("Error fetching menu data:", error);
//         }
//       }
//     };

//     const fetchTableData = async () => {
//       try {
//         const response = await axios.get(`${base_url}/qr/${token}/table`, getAuthHeaders());
//         const tableData = response.data.table;
        
//         setApprovedOrders(tableData.orders.approved.orders || []);
//         setPendingOrders(tableData.orders.pending_approval.orders || []);
//       } catch (error) {
//         console.error('Error fetching table data:', error);
//       }
//     };

//     if (!Qrcodedont) {
//       fetchTableData();
//     }
//     fetchMenuData();
//   }, [token, Qrcodedont]);

//   const handleCategoryClick = (category) => {
//     setSelectedCategory(selectedCategory === category ? null : category);
//   };

//   const handleItemClick = (item) => {
//     setSelectedItem(item);
//   };

//   const handleConfirmOrder = async (item, quantity) => {
//     try {
//       const orderData = {
//         stocks: [
//           {
//             stock_id: item.id,
//             quantity: quantity
//           }
//         ],
//         total_price: item.price * quantity
//       };

//       const response = await axios.post(
//         `${base_url}/qr/${token}/order`,
//         orderData,
//         getAuthHeaders()
//       );

//       console.log('Order submitted successfully:', response.data);
//       setPendingOrders(prevOrders => [
//         ...prevOrders,
//         orderData
//       ]);
//       window.location.reload();
//     } catch (error) {
//       if (error.response && error.response.status === 400) {
//         setQrcodedontOrder(true); // Set access denied if response status is 400
//       } else {
//         console.error('Error submitting order:', error);
//       }
//     } finally {
//       setSelectedItem(null);
//     }
//   };

//   if (QrcodedontOrde) return <QrcodeDontOrder onClose={() => setQrcodedontOrder(false)} />;
//   if (Qrcodedont) return <QrcodeDontActive onClose={() => setQrcodedont(false)} />;

//   return (
//     <>
//       <Helmet>
//         <title>Qr kodu menyusu | Smartcafe</title>
//         <meta name="description" content='Restoran proqramı | Kafe - Restoran idarə etmə sistemi ' />
//       </Helmet>
//       <div className="p-6 bg-gray-100 min-h-screen">
//         <div className="flex flex-col md:flex-row items-center mb-6">
//           <div className="md:w-1/4 mb-4 md:mb-0">
//             <img
//               src={restaurant.logo}
//               alt="Restaurant"
//               className="w-20 h-20 object-cover rounded-full border border-gray-300 shadow-md"
//             />
//           </div>
//           <div className="md:w-3/4 text-center md:text-left">
//             <h1 className="text-3xl font-bold mb-2">{restaurant.name}</h1>
//             <p>Masanin Adi : <strong>{restaurant.masaName}</strong></p>
//           </div>
//         </div>

//         <div className="border-t-2 border-b-2 border-gray-300 mb-6">
//           <div className="flex overflow-x-auto gap-3 pb-2">
//             {menu.map(category => (
//               <div
//                 key={category.id}
//                 className={`cursor-pointer py-3 px-4 rounded-lg shadow-md text-center ${selectedCategory === category.id ? 'bg-blue-500 text-white' : 'bg-blue-200 text-black'}`}
//                 onClick={() => handleCategoryClick(category.id)}
//               >
//                 <span className="font-bold text-xl">{category.name}</span>
//               </div>
//             ))}
//           </div>

//           {selectedCategory && (
//             <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-4">
//               {menu.find(category => category.id === selectedCategory)?.stocks.map((item, index) => (
//                 <div
//                   key={index}
//                   className="bg-white border rounded-lg shadow-lg overflow-hidden cursor-pointer hover:shadow-xl transition-shadow"
//                   onClick={() => handleItemClick(item)}
//                 >
//                   <img
//                     src={item.image ? `${img_url}/${item.image}` : '/placeholder-image.jpg'}
//                     alt={item.name}
//                     className="w-full h-32 object-contain"
//                   />
//                   <div className="p-4 text-center">
//                     <h4 className="text-lg font-semibold mb-2">{item.name}</h4>
//                     <p className="text-gray-600">Qiymət: {item.price} ₼</p>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>

//         {selectedItem && (
//           <MenuItemPopup
//             item={selectedItem}
//             onClose={() => setSelectedItem(null)}
//             onConfirm={handleConfirmOrder}
//           />
//         )}

//         {/* Only render tables if Qrcodedont is false */}
//         {!Qrcodedont && (
//           <div className="mt-6">
//             {/* Approved Orders Table */}
//             {Array.isArray(approvedOrders) && approvedOrders.length > 0 && (
//               <div className="bg-white p-4 rounded-lg shadow-md mb-6">
//                 <h2 className="text-xl font-semibold mb-4">Təsdiqlənmiş Sifarişlərim</h2>
//                 <table className="min-w-full divide-y divide-gray-200">
//                   <thead className="bg-gray-100">
//                     <tr>
//                       <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Adi</th>
//                       <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Miktar</th>
//                       <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Toplam</th>
//                     </tr>
//                   </thead>
//                   <tbody className="bg-white divide-y divide-gray-200">
//                     {approvedOrders.flatMap(order => order.stocks.map((stock, index) => (
//                       <tr key={`${order.order_id}-${index}`}>
//                         <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{stock.name}</td>
//                         <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.quantity}</td>
//                         <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.price} ₼</td>
//                       </tr>
//                     )))}
//                   </tbody>
//                 </table>
//                 <p className="mt-4 text-xl font-bold">Total: {approvedOrders.reduce((acc, order) => acc + order.total_price, 0)} ₼</p>
//               </div>
//             )}

//             {/* Pending Orders Table */}
//             {Array.isArray(pendingOrders) && pendingOrders.length > 0 && (
//               <div className="bg-white p-4 rounded-lg shadow-md">
//                 <h2 className="text-xl font-semibold mb-4">Təsdiq olunmamış Sifarişlərim</h2>
//                 <table className="min-w-full divide-y divide-gray-200">
//                   <thead className="bg-gray-100">
//                     <tr>
//                       <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Adi</th>
//                       <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Miktar</th>
//                       <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Toplam</th>
//                     </tr>
//                   </thead>
//                   <tbody className="bg-white divide-y divide-gray-200">
//                     {pendingOrders.flatMap(order => order.stocks.map((stock, index) => (
//                       <tr key={`${order.order_id}-${index}`}>
//                         <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{stock.name}</td>
//                         <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.quantity}</td>
//                         <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.price} ₼</td>
//                       </tr>
//                     )))}
//                   </tbody>
//                 </table>
//                 <p className="mt-4 text-xl font-bold">Toplam Məbləğ: {pendingOrders.reduce((acc, order) => acc + order.total_price, 0)} ₼</p>
//               </div>
//             )}
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

// export default RestaurantMenu;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import MenuItemPopup from '../components/MenuItemPopup'; // Ensure this path is correct
import QrcodeDontActive from '../components/QrcodeDontActive';
import QrcodeDontOrder from '../components/QrcodeDontOrder';
import { base_url, img_url } from '../api/index';
import { Helmet } from 'react-helmet';

const getAuthHeaders = () => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  };
};

const RestaurantMenu = () => {
  const [restaurant, setRestaurant] = useState({ name: '', logo: '', masaName: '' });
  const [menu, setMenu] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [approvedOrders, setApprovedOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const { token } = useParams(); // Extract token from URL parameters
  const [Qrcodedont, setQrcodedont] = useState(false);
  const [QrcodedontOrde, setQrcodedontOrder] = useState(false);

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const response = await axios.get(`${base_url}/qr/${token}/menu`, getAuthHeaders());
        setMenu(response.data.stockGroups || []); // Ensure it's an array
        setRestaurant({
          name: response.data.restaurant.name,
          logo: `${img_url}/${response.data.restaurant.logo}`,
          masaName: response.data.table.name
        });
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setQrcodedont(true); // Set access denied if response status is 400
          setRestaurant({
            name: error.response.data.restaurant.name,
            logo: `${img_url}/${error.response.data.restaurant.logo}`
          });
        } else {
          console.error("Error fetching menu data:", error);
        }
      }
    };

    const fetchTableData = async () => {
      try {
        const response = await axios.get(`${base_url}/qr/${token}/table`, getAuthHeaders());
        const tableData = response.data.table;
        
        setApprovedOrders(tableData.orders.approved.orders || []);
        setPendingOrders(tableData.orders.pending_approval.orders || []);
      } catch (error) {
        console.error('Error fetching table data:', error);
      }
    };

    if (!Qrcodedont) {
      fetchTableData();
    }
    fetchMenuData();
  }, [token, Qrcodedont]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(selectedCategory === category ? null : category);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const handleConfirmOrder = async (item, quantity) => {
    try {
      const orderData = {
        stocks: [
          {
            stock_id: item.id,
            quantity: quantity
          }
        ],
        total_price: item.price * quantity
      };

      const response = await axios.post(
        `${base_url}/qr/${token}/order`,
        orderData,
        getAuthHeaders()
      );

      console.log('Order submitted successfully:', response.data);
      setPendingOrders(prevOrders => [
        ...prevOrders,
        orderData
      ]);
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setQrcodedontOrder(true); // Set access denied if response status is 400
      } else {
        console.error('Error submitting order:', error);
      }
    } finally {
      setSelectedItem(null);
    }
  };

  if (QrcodedontOrde) return <QrcodeDontOrder onClose={() => setQrcodedontOrder(false)} />;
  if (Qrcodedont) return <QrcodeDontActive onClose={() => setQrcodedont(false)} />;

  return (
    <>
      <Helmet>
        <title>Qr kodu menyusu | Smartcafe</title>
        <meta name="description" content='Restoran proqramı | Kafe - Restoran idarə etmə sistemi ' />
      </Helmet>
      <div className="p-6 bg-gray-100 min-h-screen">
        <div className="flex flex-col md:flex-row items-center mb-6">
          <div className="md:w-1/4 mb-4 md:mb-0">
            <img
              src={restaurant.logo}
              alt="Restaurant"
              className="w-20 h-20 object-cover rounded-full border border-gray-300 shadow-md"
            />
          </div>
          <div className="md:w-3/4 text-center md:text-left">
            <h1 className="text-3xl font-bold mb-2">{restaurant.name}</h1>
            <p>Masanin Adi : <strong>{restaurant.masaName}</strong></p>
          </div>
        </div>

        <div className="border-t-2 border-b-2 border-gray-300 mb-6">
          {menu.map(category => (
            <div key={category.id} className="mb-4">
              <div
                className="cursor-pointer py-3 px-4 bg-gray-200 rounded-lg shadow-md hover:bg-gray-300"
                onClick={() => handleCategoryClick(category.id)}
              >
                <span className="font-bold text-xl">{category.name}</span>
                <span className="ml-4">{selectedCategory === category.id ? '↓' : '→'}</span>
              </div>
              {selectedCategory === category.id && (
                <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-4">
                  {category.stocks.map((item, index) => (
                    <div
                      key={index}
                      className="bg-white border rounded-lg shadow-lg overflow-hidden cursor-pointer hover:shadow-xl transition-shadow"
                      onClick={() => handleItemClick(item)}
                    >
                      <img
                        src={item.image ? `${img_url}/${item.image}` : '/placeholder-image.jpg'}
                        alt={item.name}
                        className="w-full h-32 object-contain"
                      />
                      <div className="p-4 text-center">
                        <h4 className="text-lg font-semibold mb-2">{item.name}</h4>
                        <p className="text-gray-600">Qiymət: {item.price} ₼</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>

        {selectedItem && (
          <MenuItemPopup
            item={selectedItem}
            onClose={() => setSelectedItem(null)}
            onConfirm={handleConfirmOrder}
          />
        )}

        {/* Only render tables if Qrcodedont is false */}
        {!Qrcodedont && (
          <div className="mt-6">
            {/* Approved Orders Table */}
            {Array.isArray(approvedOrders) && approvedOrders.length > 0 && (
              <div className="bg-white p-4 rounded-lg shadow-md mb-6">
                <h2 className="text-xl font-semibold mb-4">Təsdiqlənmiş Sifarişlərim</h2>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Adi</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Miktar</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Toplam</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {approvedOrders.flatMap(order => order.stocks.map((stock, index) => (
                      <tr key={`${order.order_id}-${index}`}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{stock.name}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.quantity}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.price} ₼</td>
                      </tr>
                    )))}
                  </tbody>
                </table>
                <p className="mt-4 text-xl font-bold">Total: {approvedOrders.reduce((acc, order) => acc + order.total_price, 0)} ₼</p>
              </div>
            )}

            {/* Pending Orders Table */}
            {Array.isArray(pendingOrders) && pendingOrders.length > 0 && (
              <div className="bg-white p-4 rounded-lg shadow-md">
                <h2 className="text-xl font-semibold mb-4">Təsdiq olunmamış Sifarişlərim</h2>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Adi</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Miktar</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Toplam</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {pendingOrders.flatMap(order => order.stocks.map((stock, index) => (
                      <tr key={`${order.order_id}-${index}`}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{stock.name}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.quantity}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.price} ₼</td>
                      </tr>
                    )))}
                  </tbody>
                </table>
                <p className="mt-4 text-xl font-bold">Toplam Məbləğ: {pendingOrders.reduce((acc, order) => acc + order.total_price, 0)} ₼</p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default RestaurantMenu;