

import React, { useState } from 'react';

const MenuItemPopup = ({ item, onClose, onConfirm }) => {
  const [quantity, setQuantity] = useState(1);

  const increaseQuantity = () => setQuantity(quantity + 1);
  const decreaseQuantity = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };

  const handleConfirm = () => {
    onConfirm(item, quantity);
    onClose(); // Close the popup after confirming
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg w-96 shadow-lg">
        <h3 className="text-xl font-semibold mb-4">{item.name}</h3>
        <div className="flex items-center mb-4">
          <button
            onClick={decreaseQuantity}
            className="px-4 py-2 bg-gray-200 text-gray-600 rounded-l hover:bg-gray-300"
          >
            -
          </button>
          <span className="mx-4 text-lg">{quantity}</span>
          <button
            onClick={increaseQuantity}
            className="px-4 py-2 bg-gray-200 text-gray-600 rounded-r hover:bg-gray-300"
          >
            +
          </button>
        </div>
        <p className="text-lg font-semibold mb-4">Toplam Qiymət : {item.price * quantity} ₼</p>
        <div className="flex justify-between">
          <button
            onClick={handleConfirm}
            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
          >
            Sifarişi gondər
          </button>
          <button
            onClick={onClose}
            className="text-red-500 font-semibold hover:underline"
          >
            Kapat
          </button>
        </div>
      </div>
    </div>
  );
};

export default MenuItemPopup;
