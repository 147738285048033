import React from 'react'

function Footer() {
  return (
   <>
   <footer>
   <div className='text-center bg-white p-8 mt-16'>
     <h3>Bu sayt Digigo Group tərəfindən hazırlanmişdir. 2024-ci il, bütün hüquqlar qorunur</h3>

   </div>

   </footer>
   </>
  )
}

export default Footer