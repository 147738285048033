
// import React, { useEffect, useState } from 'react';
// import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
// import Login from './pages/Login';
// import Header from './components/Header';
// import Couriers from './pages/Couriers';
// import GunlukKasa from './pages/GunlukKasa';
// import Masalar from './pages/Masalar';
// import Stok from './pages/Stok';
// import MasaTanimlari from './pages/MasaTanimlari';
// import PersonelTanimlari from './pages/PersonelTanimlari';
// import Siparisler from './pages/Siparisler';
// import Musteriler from './pages/Musteriler';
// import MasaSiparis from './pages/MasaSiparis';
// import MusteriSiparisEkle from './pages/MusteriSiparisEkle';
// import GenelAyarlar from './pages/GenelAyarlar';
// import OrderDetailsQrcod from './pages/OrderDetailsQrcod';
// import MasaTanimlariId from './pages/MasaTanimlariId';
// import AdminLogin from './pages/AdminLogin';
// import Dashboard from './pages/Dashboard';
// import NotFoundPage from './pages/NotFoundPage';
// import axios from 'axios';
// import DontActiveAcount from './components/DontActiveAcount';
// import { base_url,img_url } from '../src/api/index';
// import Footer from './components/Footer';
// import DeleteAcount from './components/DeleteAcount';
// const getHeaders = () => ({
//     headers: {
//         'Authorization': `Bearer ${localStorage.getItem('token')}`,
//         'Content-Type': 'application/json',
//         'Accept': 'application/json',
//     }
// });
// const App = () => {
//     const location = useLocation();
//     const [ActiveUser, setActiveUser] = useState(false);
//     const [deleteAcount, setdeleteAcount] = useState(false);
//     const [ChangePassword, setChangePassword] = useState(false);
//     // Determine if the header should be shown
//     const showHeader = !(
//         location.pathname.startsWith('/adminPage') || 
//         location.pathname.startsWith('/order-details')
//       );
//       const [role,setrole] =useState(localStorage.getItem('role'))
//       useEffect(() => {
//         const fetchOrders = async () => {
//             try {
//                 const response = await axios.get(`${base_url}/own-restaurants`, getHeaders());
              
//             } catch (error) {

//                 console.log(error.response.data.message);
//                 if (error.response && error.response.status === 401 && error.response.data.message === "Unauthenticated." ) {
//                     setdeleteAcount(true); // Set access denied if response status is 403
//                 }
//                 // if (error.response && error.response.status === 401 && error.response.data.message === "Unauthenticated" ) {
//                 //     setChangePassword(true); // Set access denied if response status is 403
//                 // }
//                 if (error.response &&( error.response.status === 403 && error.response.data.message === "User does not belong to any  active restaurant.")) {
//                     setActiveUser(true); // Set access denied if response status is 403
//                 }
//                 else{

//                     console.error('Error fetching orders:', error);
//                 }
//             }
//         };
//        fetchOrders();
//     }, []);
   
//     if (ActiveUser) return <DontActiveAcount  sil={setActiveUser}/>;
//     if (deleteAcount) return <DeleteAcount  sil={setdeleteAcount}/>;
//     if (ChangePassword) return <ChangePassword  sil={setChangePassword}/>;
//     return (
//         <div>
//             {showHeader && <Header />}
//             <Routes>
//                 <Route path='/' element={<Login />} />
//                 <Route path='/masalar' element={<Masalar />} />
//                 <Route path='/masa-siparis/:id' element={<MasaSiparis />} />
//                 {role !== 'waiter' && (
//                     <>
//                                     <Route path='/siparisler' element={<Siparisler />} />
//                 <Route path='/musteriler' element={<Musteriler />} />
//                 <Route path='/gunluk-kasa' element={<GunlukKasa />} />
//                 <Route path='/stok' element={<Stok />} />
//                 <Route path='/couriers' element={<Couriers />} />
//                 <Route path='/masa-tanimlari' element={<MasaTanimlari />} />
//                 <Route path='/masa-tanimlari/id' element={<MasaTanimlariId />} />
//                 <Route path='/personel-tanimlari' element={<PersonelTanimlari />} />
//                 <Route path='/muster-siparis-ekle/:id' element={<MusteriSiparisEkle />} />
//                 <Route path='/genel-ayarlar' element={<GenelAyarlar />} />
//                 <Route path='/order-details/:token' element={<OrderDetailsQrcod />} />
//                     </>
//                 )}

//                 <Route path='/adminPage' element={<AdminLogin />} />
//                 <Route path='/adminPage/dashboard' element={<Dashboard />} />
//                 <Route path="*" element={<NotFoundPage />} />
//             </Routes>
//             {/* <Footer></Footer> */}
//         </div>
//     );
// };

// const AppWithRouter = () => (
//     <BrowserRouter>
//         <App />
//     </BrowserRouter>
// );

// export default AppWithRouter;

import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Login from './pages/Login';
import Header from './components/Header';
import Couriers from './pages/Couriers';
import GunlukKasa from './pages/GunlukKasa';
import Masalar from './pages/Masalar';
import Stok from './pages/Stok';
import MasaTanimlari from './pages/MasaTanimlari';
import PersonelTanimlari from './pages/PersonelTanimlari';
import Siparisler from './pages/Siparisler';
import Musteriler from './pages/Musteriler';
import MasaSiparis from './pages/MasaSiparis';
import MusteriSiparisEkle from './pages/MusteriSiparisEkle';
import GenelAyarlar from './pages/GenelAyarlar';
import OrderDetailsQrcod from './pages/OrderDetailsQrcod';
import MasaTanimlariId from './pages/MasaTanimlariId';
import AdminLogin from './pages/AdminLogin';
import Dashboard from './pages/Dashboard';
import NotFoundPage from './pages/NotFoundPage';
import axios from 'axios';
import DontActiveAcount from './components/DontActiveAcount';
import { base_url, img_url } from '../src/api/index';
import Footer from './components/Footer';
import DeleteAcount from './components/DeleteAcount';
import ChangePassword from './components/ChangePassword'; // Ensure this path is correct

const getHeaders = () => ({
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
});

const App = () => {
    const location = useLocation();
    const [activeUser, setActiveUser] = useState(false);
    const [deleteAccount, setDeleteAccount] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                await axios.get(`${base_url}/qr/orders/all`, getHeaders());
            } catch (error) {
                console.log(error.response?.data?.message);
                if (error.response?.status === 401 && error.response?.data?.message === "Unauthenticated.") {
                    setDeleteAccount(true);
                } else if (error.response?.status === 403 && error.response?.data?.message === "User does not belong to any active restaurant.") {
                    setActiveUser(true);
                } else {
                    console.error('Error fetching orders:', error);
                }
            } finally {
                setLoading(false);
            }
        };
        fetchOrders();
    }, []);

    if (loading) return <div>Loading...</div>; // Or a proper loading spinner

    const showHeader = !(
        location.pathname.startsWith('/adminPage') || 
        location.pathname.startsWith('/order-details')
    );

    const role = localStorage.getItem('role');

    const showErrors = !(
        location.pathname === '/' || 
        location.pathname.startsWith('/adminPage') ||
        location.pathname.startsWith('/order-details')
    );

    if (activeUser && showErrors) return <DontActiveAcount onClose={() => setActiveUser(false)} />;
    if (deleteAccount && showErrors) return <DeleteAcount onClose={() => setDeleteAccount(false)} />;
    if (changePassword && showErrors) return <ChangePassword onClose={() => setChangePassword(false)} />;

    return (
        <div>
            {showHeader && <Header />}
            <Routes>
                <Route path='/' element={<Login />} />
                <Route path='/masalar' element={<Masalar />} />
                <Route path='/masa-siparis/:id' element={<MasaSiparis />} />
                {role !== 'waiter' && (
                    <>
                        <Route path='/siparisler' element={<Siparisler />} />
                        <Route path='/musteriler' element={<Musteriler />} />
                        <Route path='/gunluk-kasa' element={<GunlukKasa />} />
                        <Route path='/stok' element={<Stok />} />
                        <Route path='/couriers' element={<Couriers />} />
                        <Route path='/masa-tanimlari' element={<MasaTanimlari />} />
                        <Route path='/masa-tanimlari/id' element={<MasaTanimlariId />} />
                        <Route path='/personel-tanimlari' element={<PersonelTanimlari />} />
                        <Route path='/muster-siparis-ekle/:id' element={<MusteriSiparisEkle />} />
                        <Route path='/genel-ayarlar' element={<GenelAyarlar />} />
                        <Route path='/order-details/:token' element={<OrderDetailsQrcod />} />
                    </>
                )}
                <Route path='/adminPage' element={<AdminLogin />} />
                <Route path='/adminPage/dashboard' element={<Dashboard />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
            {/* <Footer /> */}
        </div>
    );
};

const AppWithRouter = () => (
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

export default AppWithRouter;
