

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import OncedenOde from '../components/OncedenOde';
import HesapKes from '../components/HesapKes';
import AccessDenied from '../components/AccessDenied';
import { base_url,img_url } from '../api/index';
import { Helmet } from 'react-helmet';
import DontActiveAcount from '../components/DontActiveAcount';
// Helper function to get headers
const getHeaders = () => ({
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
});

function MasaSiparis() {
    const { id } = useParams(); // Get the table ID from URL parameters
    const [urunType, setUrunType] = useState(0); // Default to "Hamısı"
    const [stockGroups, setStockGroups] = useState([]);
    const [stocks, setStocks] = useState([]);
    const [tableName, setTableName] = useState(''); // Default table name
    const [totalPrice, setTotalPrice] = useState({}); // Default total price as a number
    const [orderDetails, setOrderDetails] = useState([]); // Details of the table's orders
    const [odersIdMassa, setOrdersIdMassa] = useState({});
    const [refreshFetch, setRefreshFetch] = useState(false);
    const [oncedenodePopop, setOncedenodePopop] = useState(false);
    const [HesabKes, setHesabKes] = useState(false);
    const navigate = useNavigate();
    const [accessDenied, setAccessDenied] = useState(false);
    const [role,setrole] =useState(localStorage.getItem('role'))
    const fis = localStorage.getItem('fisYazisi')
    const [ActiveUser, setActiveUser] = useState(false);
    // Fetch stock groups from API
    const fetchStockGroups = async () => {
        try {
            const response = await axios.get(`${base_url}/stock-groups`, getHeaders());
            setStockGroups(response.data);
        } catch (error) {
            if (error.response && error.response.status === 403 && error.response.data.message === "User does not belong to any  active restaurant.") {
                setActiveUser(true); // Set access denied if response status is 403
            }
            if (error.response && error.response.status === 403 && error.response.data.message === "Forbidden") {
                // setAccessDenied(true); // Set access denied if response status is 403
            } else {
                console.error('Error loading customers:', error);
            }
        }
    };

    // Fetch stocks from API with optional filter
    const fetchStocks = async (groupId) => {
        try {
            const response = await axios.get(`${base_url}/stocks`, {
                ...getHeaders(),
                params: groupId === 0 ? {} : { stock_group_id: groupId } // No filter if urunType is 0
            });
            setStocks(response.data);
        } catch (error) {
            if (error.response && error.response.status === 403 && error.response.data.message === "User does not belong to any  active restaurant.") {
                setActiveUser(true); // Set access denied if response status is 403
            }
            if (error.response && error.response.status === 403 && error.response.data.message === "Forbidden") {
                // setAccessDenied(true); // Set access denied if response status is 403
            } else {
                console.error('Error loading customers:', error);
            }
        }
    };

    // Fetch table orders
    const fetchTableOrders = async () => {
        try {
            const response = await axios.get(`${base_url}/tables/${id}/order`, getHeaders());
            const orders = response.data.table.orders;
            setTableName(response.data.table.name);
            setOrdersIdMassa({
                id: response.data.table.orders[0].order_id,
                total_price: response.data.table.orders[0].total_price,
                total_prepayment: response.data.table.orders[0].total_prepayment
            });
            const formattedOrders = orders.map(order => ({
                totalPrice: order.total_price, // Extract total price
                total_prepayment: order.total_prepayment,
                items: order.stocks.map(stock => ({
                    id: stock.id,
                    name: stock.name,
                    quantity: stock.quantity,
                    price: stock.price
                }))
            }));

            // Flatten items and update state
            const allItems = formattedOrders.flatMap(order => order.items);
            setOrderDetails(allItems);

            // Calculate total price
            const total = formattedOrders.reduce((acc, order) =>  order.totalPrice, 0);
            const total_prepare = formattedOrders.reduce((acc, order) =>order.total_prepayment, 0);
            const kalanMebleg = total - total_prepare;
            setTotalPrice({
                total: total,
                total_prepare: total_prepare,
                kalan: kalanMebleg
            });

        } catch (error) {
            if (error.response && error.response.status === 403 && error.response.data.message === "User does not belong to any  active restaurant.") {
                setActiveUser(true); // Set access denied if response status is 403
            }
            if (error.response && error.response.status === 403 && error.response.data.message === "Forbidden") {
                // setAccessDenied(true); // Set access denied if response status is 403
            } else {
                console.error('Error loading customers:', error);
            }
        }
    };

    // Delete orders
    const handleDeleteMasa = async () => {
        try {
            await axios.delete(`${base_url}/tables/${id}/cancel-order`, getHeaders());
            window.location.reload();
            navigate('/masalar');
        } catch (error) {
            if (error.response && error.response.status === 403 && error.response.data.message === "User does not belong to any  active restaurant.") {
                setActiveUser(true); // Set access denied if response status is 403
            }
            if (error.response && error.response.status === 403 && error.response.data.message === "Forbidden") {
                setAccessDenied(true); // Set access denied if response status is 403
            } else {
                console.error('Error deleting masa:', error);
            }
        }
    };

    // Replace image URL with the full path
    const replaceImage = (url) => {
        return url ? `${img_url}/${url}` : ''; // Ensure URL is valid
    };

    // Retrieve urunType from localStorage and fetch initial data
    useEffect(() => {
        const storedUrunType = localStorage.getItem('urunType');
        if (storedUrunType) {
            setUrunType(Number(storedUrunType));
        }
        fetchStockGroups();
        fetchTableOrders();
    }, [id, refreshFetch]);

    // Fetch stocks when urunType changes
    useEffect(() => {
        fetchStocks(urunType);
        localStorage.setItem('urunType', urunType); // Save urunType to localStorage
    }, [urunType]);

    // Handler to add stock to the order
    const handleAddStock = async (stockId) => {
        try {
            await axios.post(`${base_url}/tables/${id}/add-stock`, {
                stock_id: stockId,
                quantity: 1 // Default quantity
            }, getHeaders());
            fetchTableOrders(); // Refresh orders after adding stock
        } catch (error) {
            if (error.response && error.response.status === 403 && error.response.data.message === "User does not belong to any  active restaurant.") {
                setActiveUser(true); // Set access denied if response status is 403
            }
            if (error.response && error.response.status === 403 && error.response.data.message === "Forbidden") {
                setAccessDenied(true); // Set access denied if response status is 403
            } else {
                console.error('Error adding stock to order:', error);
            }
        }
    };

    // Handler to remove stock from the order
    const handleRemoveStock = async (stockId, quantity) => {
        try {
            await axios.post(`${base_url}/tables/${id}/subtract-stock`, {
                stock_id: stockId,
                quantity: quantity || 1 // Default quantity
            }, getHeaders());
            fetchTableOrders(); // Refresh orders after removing stock
        } catch (error) {
            if (error.response && error.response.status === 403 && error.response.data.message === "User does not belong to any  active restaurant.") {
                setActiveUser(true); // Set access denied if response status is 403
            }
            if (error.response && error.response.status === 403 && error.response.data.message === "Forbidden") {
                setAccessDenied(true); // Set access denied if response status is 403
            } else {
                console.error('Error removing stock from order:', error);
            }
        }
    };

    // Print function
    const handlePrint = () => {
        const now = new Date();
        const formattedDate = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;
        const formattedTime = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;

        const printContent = `
            <html>
              <head>
                <title>Print Order</title>
                <style>
                  body { font-family: Arial, sans-serif; }
                  .container { width: 100%; max-width: 600px; margin: auto; }
                  .header { text-align: center; margin-bottom: 20px; }
                  .order-list { margin-bottom: 20px; }
                  .order-list table { width: 100%; border-collapse: collapse; }
                  .order-list table, .order-list th, .order-list td { border: 1px solid black; }
                  .order-list th, .order-list td { padding: 8px; text-align: left; }
                  .footer { text-align: right; margin-top: 20px; }
                  .fis {text-align: center; margin-top: 20px; }
                </style>
              </head>
              <body>
                <div class="container">
                  <div class="header">
                    <h1>${tableName}</h1>
                    <p>Açılış vaxtı: ${formattedDate} ${formattedTime}</p>
                  </div>
                  <div class="order-list">
                    <table>
                      <thead>
                        <tr>
                          <th>Məhsul</th>
                          <th>Miktar</th>
                          <th>Qiymət</th>
                        </tr>
                      </thead>
                      <tbody>
                        ${orderDetails.map(item => `
                          <tr>
                            <td>${item.name}</td>
                            <td>${item.quantity}</td>
                            <td>${item.price}</td>
                          </tr>
                        `).join('')}
                      </tbody>
                    </table>
                  </div>
                  <div class="footer">
                    <p>Toplam: ${totalPrice.total} ₼</p>
                    <p>Artıq ödənilib: ${totalPrice.total_prepare} ₼</p>
                    <p>Qaliq: ${totalPrice.kalan} ₼</p>
                  </div>
                  <div class="fis">

                  <strong>${fis}</strong>
                  </div>
                </div>
              </body>
            </html>
        `;

        const printWindow = window.open('', '', 'width=800,height=600');
        printWindow.document.open();
        printWindow.document.write(printContent);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
    };
    // if (ActiveUser) return <DontActiveAcount onClose={setActiveUser}/>;
    if (accessDenied) return <AccessDenied onClose={setAccessDenied} />;

    return (
        <>
                           <Helmet>
        <title>Masaların sifarişi | Smartcafe</title>
        <meta name="description" content='Restoran proqramı | Kafe - Restoran idarə etmə sistemi ' />
      </Helmet>
      <section className="p-6 flex flex-col md:flex-row gap-6">
  {/* Order Summary Section */}
  <div className='border rounded-lg bg-gray-50 p-4 flex-2'>
    <div className='flex items-center justify-between mb-4'>
      <Link className='bg-blue-600 text-white py-2 px-4 rounded flex items-center gap-2' to='/masalar'>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-left" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"></path>
          <path fillRule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"></path>
        </svg>
        Masalar
      </Link>
      <h2 className='text-xl font-semibold'>{tableName}</h2>
    </div>
    <div className="overflow-x-auto">
      <table className='w-full bg-white border border-gray-200 rounded-md shadow-md table-auto'>
        <thead className='bg-gray-100 border-b border-gray-300'>
          <tr>
            <th className='p-3 text-left font-semibold'>Sil</th>
            <th className='p-3 text-left font-semibold'>Adı</th>
            <th className='p-3 text-left font-semibold'>Miktar</th>
            <th className='p-3 text-left font-semibold'>Toplam</th>
          </tr>
        </thead>
        <tbody>
          {orderDetails.map((item) => (
            <tr key={item.id} className='border-b'>
              <td onClick={() => handleRemoveStock(item.id, item.quantity)} className='p-3 text-red-500 cursor-pointer text-center'> 
                <i className="fa-solid fa-trash-can"></i>
              </td>
              <td className='p-3'>{item.name}</td>
              <td className='p-3'>
                <div className='flex items-center'>
                  <button onClick={() => handleRemoveStock(item.id)} className='bg-red-500 text-white py-1 px-2 rounded-l focus:outline-none'>-</button>
                  <input type="number" value={item.quantity} className='border-t border-b p-2 text-right w-20 text-lg' readOnly />
                  <button onClick={() => handleAddStock(item.id)} className='bg-green-500 text-white py-1 px-2 rounded-r focus:outline-none'>+</button>
                </div>
              </td>
              <td className='p-3 text-right'>{item.price} ₼</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    {role !== 'waiter' && (
      <div className='flex flex-col gap-4 mt-4'>
        {totalPrice.total ? (
          <>
            <div className='flex items-center gap-2'>
              <span className='font-semibold'>Ön ödeme:</span>
              <input type="text" value={totalPrice.total_prepare} readOnly className='border rounded-l p-2 text-right w-20' />
              <button onClick={() => setOncedenodePopop(true)} className='bg-green-500 text-white py-1 px-2 rounded-r focus:outline-none'>+</button>
            </div>
            <div className='flex items-center gap-2'>
              <span className='font-semibold'>Toplam:</span>
              <div className='flex items-center'>
                <div className='border font-medium py-1 px-2 rounded-l bg-gray-100'>₼</div>
                <div className='border border-l-0 rounded-r p-2 w-32 text-right bg-gray-100'>{totalPrice.total}</div>
              </div>
            </div>
            <div className='flex items-center gap-2'>
              <span className='text-green-600 font-semibold'>Artıq ödənilib :</span>
              <div className='flex items-center'>
                <div className='border font-medium py-1 px-2 rounded-l bg-gray-100'>₼</div>
                <div className='border border-l-0 rounded-r p-2 w-32 text-right bg-gray-100'>{totalPrice.total_prepare}</div>
              </div>
            </div>
            <div className='flex items-center gap-2'>
              <span className='text-red-600 font-semibold'>Qalıq :</span>
              <div className='flex items-center'>
                <div className='border font-medium py-1 px-2 rounded-l bg-gray-100'>₼</div>
                <div className='border border-l-0 rounded-r p-2 w-32 text-right bg-gray-100'>{totalPrice.kalan}</div>
              </div>
            </div>
          </>
        ) : "Sifaris yoxdur"}

        <div className='flex gap-2 mt-4'>
          {totalPrice.total && (
            <>
              <button onClick={() => setHesabKes(true)} className='bg-green-500 text-white py-2 px-4 rounded flex items-center gap-2'>
                Hesap kes
              </button>
              <button onClick={handlePrint} className='bg-blue-600 text-white py-2 px-4 rounded flex items-center gap-2'>
              Qəbz çap edin
              </button>
            </>
          )}
          <button onClick={() => handleDeleteMasa()} className='bg-gray-800 text-white py-2 px-4 rounded flex items-center gap-2'>
          Ləğv edin
          </button>
        </div>
      </div>
    )}
  </div>

  {/* Product Selection Section */}
  <div className='border rounded-lg bg-gray-50 p-4 flex-1'>
    <div className='flex flex-wrap gap-2 mb-4'>
      <button onClick={() => setUrunType(0)} className={`p-2 btn-filter ${urunType === 0 ? 'bg-blue-500 text-white border-blue-500' : 'bg-gray-200'}`}>Hamısı</button>
      {stockGroups.map(group => (
        <button key={group.id} onClick={() => setUrunType(group.id)} className={`p-2 btn-filter ${urunType === group.id ? 'bg-blue-500 text-white border-blue-500' : 'bg-gray-200'}`}>
          {group.name}
        </button>
      ))}
    </div>
    <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4'>
      {stocks.map(stock => (
        <div
          key={stock.id}
          className='bg-white border rounded-lg p-4 shadow-md flex flex-col cursor-pointer'
          onClick={() => handleAddStock(stock.id)}
        >
          <div className='w-full h-32 bg-gray-300 mb-2'>
            <img src={replaceImage(stock.image)} alt={stock.name} className='w-full h-full object-contain' />
          </div>
          <div className='flex-grow'>
            <span className='block text-lg font-semibold'>{` ${stock.price} ₼`}</span>
            <p className='text-sm text-gray-600 truncate'>{stock.name}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
</section>

            {
                oncedenodePopop && (
                    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center p-4 z-50">
                        <div className="w-full max-w-3xl bg-white rounded-lg shadow-lg overflow-hidden border border-gray-300 relative">
                            <div className="bg-gray-200 p-4 flex justify-between items-center border-b">
                                <h3 className="text-xl font-semibold text-gray-800">{tableName}</h3>
                                <button
                                    onClick={() => setOncedenodePopop(false)}
                                    className="text-gray-500 hover:text-gray-700 focus:outline-none"
                                    aria-label="Close"
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="p-4 max-h-[80vh] overflow-y-auto">
                                <OncedenOde odersId={odersIdMassa} setrefreshfetch={setRefreshFetch} />
                            </div>
                        </div>
                    </div>
                )
            }
            {
                HesabKes && (
                    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center p-4 z-50">
                        <div className="w-full max-w-3xl bg-white rounded-lg shadow-lg overflow-hidden border border-gray-300 relative">
                            <div className="bg-gray-200 p-4 flex justify-between items-center border-b">
                                <h3 className="text-xl font-semibold text-gray-800">{tableName}</h3>
                                <button
                                    onClick={() => setHesabKes(false)}
                                    className="text-gray-500 hover:text-gray-700 focus:outline-none"
                                    aria-label="Close"
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="p-4 max-h-[80vh] overflow-y-auto">
                                <HesapKes orderId={odersIdMassa} totalAmount={totalPrice.kalan} />
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
}

export default MasaSiparis;
